import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import ContactPage from "./pages/Contact";
import Customer from "./pages/Customer";
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import WorkPage from './pages/WorkPage';
import HeaderFooter from './pages/sections/HeaderFooter';
import ProductsMain from './pages/products/ProductsMain';
import WebDesign from './pages/products/WebDesign';
import ScrollToTop from './widgets/ScrollToTop';
import ThankYou from './pages/ThankYou';
import AiChat from './pages/products/AiChat';
import AIPage from './pages/AIPage';
import ShopifyPage from './pages/products/Shopify';


function App() {
  return <ThemeProvider theme={theme}>

    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
          </Route>

          <Route path="/our-work" element={<HeaderFooter />}>
            <Route index element={<WorkPage />}></Route>
          </Route>

          <Route path="products" element={<HeaderFooter />}>
            <Route index element={<ProductsMain />}></Route>
            <Route path="web-design" element={<WebDesign />}></Route>
            <Route path="ai-chat" element={<AiChat />}></Route>
            <Route path="ai-services" element={<AIPage />}></Route>
            <Route path="shopify" element={<ShopifyPage />}></Route>
          </Route>




          <Route path="/contact" element={<ContactPage />}>
          </Route>

          <Route path="/projects/:customer" element={<Customer />}>
          </Route>

          <Route path="/thankyou" element={<HeaderFooter />}>
            <Route index element={<ThankYou />}></Route>
          </Route>

        </Routes>
      </ScrollToTop>

    </BrowserRouter>
    {/* WhatsApp icon */}
    <a
      href="https://wa.me/60196227228?text=Hi%2C%20can%20I%20know%20more%20about%20your%20products%3F"
      className="whatsapp_float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-whatsapp whatsapp-icon"></i>
    </a>

  </ThemeProvider>;

}

export default App;
