import Container from "@mui/material/Container";
import '../../styles/shopify.css';
import Grid from "@mui/material/Grid";
import ContactBtn from '../../widgets/ContactButton';

const Shopify = () => {
    return (
        <div className="shopify">
            <Container className="shopify-header" sx={{ backgroundImage: 'url(https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg)', backgroundSize: 'cover', backgroundPosition: 'center', padding: 5, marginY: 5, color: 'white', height: '300px', alignContent: 'center' }}>
                <h1 style={{ color: 'white' }}>Expert Shopify Web Developers & Shopify Partners</h1>
                <p style={{ color: 'white' }}>Custom Shopify Plus Store Setup & Development</p>
            </Container>

            <Container className="shopify-section" sx={{ textAlign: 'center' }}>
                <h2>Why Choose Our Shopify Web Developers?</h2>
                <p>We are certified Shopify Partners, helping businesses scale with Shopify Plus store setups and custom development.</p>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6} alignItems={'center'} alignContent={'center'}>
                        <ul className="bullet-points">
                            <li>Custom Shopify Theme Development</li>
                            <li>Seamless Shopify Plus Store Setup</li>
                            <li>Optimized Shopify Web Design & Mobile Responsiveness</li>
                            <li>SEO Strategies for Higher Rankings</li>
                            <li>Ongoing Support & Maintenance</li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src="https://images.pexels.com/photos/3183153/pexels-photo-3183153.jpeg" alt="Shopify Web Developer" className="shopify-image" />
                    </Grid>
                </Grid>
            </Container>

            <Container className="shopify-section">
                <h2>Shopify Partners Who Drive Success</h2>
                <p>Our team of expert Shopify web developers has empowered businesses worldwide. Here's what our clients say:</p>
                <blockquote>“The best Shopify web developers! Our Shopify Plus store setup was seamless, and sales soared.” – Happy Client</blockquote>
                <blockquote>“Reliable Shopify Partners who transformed our online presence.” – Satisfied Customer</blockquote>
            </Container>

            <Container className="shopify-section">
                <h2>Our Shopify Plus Store Setup Process</h2>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6} alignSelf={'center'}>
                        <div>
                            <p>We streamline Shopify Plus store development for optimal performance:</p>
                            <ol>
                                <li>Consultation & Strategy Planning</li>
                                <li>Shopify Theme Customization & UX Design</li>
                                <li>Shopify Plus Store Setup & Integration</li>
                                <li>SEO Optimization & Testing</li>
                                <li>Launch & Ongoing Shopify Support</li>
                            </ol>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src="https://images.pexels.com/photos/3184305/pexels-photo-3184305.jpeg" alt="Shopify Plus Store Setup" className="shopify-image" />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ textAlign: 'center', marginBottom: 5 }}>
                <ContactBtn />
            </Container>
        </div >
    );
}

export default Shopify;
