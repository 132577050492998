import Button from "@mui/material/Button";

const ContactBtn = () => {
    return (
        <Button variant="contained" sx={{ color: 'white' }} href="/contact">
            Get In Touch
        </Button>
    )
}

export default ContactBtn;